export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://seasonal-storage.amgreetings.com/',
  apiScopeUrl: 'api://8aa01ed0-f616-45aa-bf3a-ba28a8d1a7c9/access_as_user',
  clientId: '86bb156c-5631-428c-a3b4-d032d2e2adec',
  postLogoutRedirectUri: 'https://seasonal-storage.amgreetings.com/',
  webApi: 'https://seasonal-storage.prod.ag2goservices.amgreetings.com/api',
  userRolesAuthorization: false,
  tablePaginationSizes: [10, 25, 50, 75, 100, 150, 200, 250, 500, 1000, 2000],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100,
};
